import React from 'react';
import axios from 'axios';
import data from '../assets/resources';
import './assets/Order.css';
import StepProgressBarPickup from './components/progress-bar-pickup/ProgressBarPickup';
import StepProgressBarDelivery from './components/progress-bar-delivery/ProgressBarDelivery';
import CartDescription from '../Shared/cart-description/CartDescription';
import { Link } from 'react-router-dom';
import Pusher from 'pusher-js';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import loadingImage from './../assets/minys/images/loading.gif'

class Order extends React.Component {

    state = {
        order: null,
        cartLength: 0,
        delivery: null
    }
    componentDidMount() {
        document.title = `Order | ${process.env.REACT_APP_NAME}`;
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_ID, {
            cluster: 'us2',
            encrypted: true
        });
        const channel = pusher.subscribe('delivery');
        channel.bind('update', data => {
             if(this.state.order.fulfillments[0].deliveryDetails.squareDeliveryId === data.message.data.id){
                this.setState({
                    delivery: data.message.data
                });
             }
        });
    }
    clearStorage(){
        localStorage.clear();
    }
    
    render() {
        if (!this.state?.order) {
            this.getOrder();
            return (
                <LoadingScreen imageSource={loadingImage} />
            )  
        }
        else {
            const statusTextMap = {
              created: 'Delivery created',
              enroute_to_pickup: 'Picking up',
              arrived_at_pickup: 'Arrived to Pick up',
              picked_up: 'Picked Up',
              arrived_at_dropoff: 'Arrived at Dropoff',
              delivered: 'Delivered',
              confirmed: 'New Dasher Confirmed',
              cancelled: 'Cancelled'
            };
        
            const deliveryStatus = this.state.delivery?.delivery_status;
            const statusText = statusTextMap[deliveryStatus] || this.state.delivery?.delivery_status;
            const location = this.props?.locations.find(location => location.id === this.state?.order?.locationId);
            let PaymentMethod;

            const capturedTender = this.state?.order?.tenders.find(tender => tender.type === 'WALLET' || (tender.cardDetails && tender.cardDetails.status === 'CAPTURED') || (tender.cardDetails && tender.cardDetails.status === 'AUTHORIZED'));
            const cardDetailsStatus = capturedTender?.cardDetails?.status;

            if (capturedTender) {
            if (capturedTender.type === 'WALLET') {
                PaymentMethod = (
                <p className='poppins text-color6 fw-semibold mt-3'>
                    {capturedTender.type}<br />
                    Paid: ${(capturedTender.amountMoney.amount) / 100}<br />
                    Status: {cardDetailsStatus}
                </p>
                );
            } else {
                PaymentMethod = (
                <p className='poppins text-color6 fw-semibold mt-3'>
                    {capturedTender.type}<br />
                    {capturedTender.cardDetails.card.cardBrand}<br />
                    XXXX-XXXX-XXXX-{capturedTender.cardDetails.card.last4}<br />
                    Paid: ${(capturedTender.amountMoney.amount) / 100}<br />
                    Status: {cardDetailsStatus}
                </p>
                );
            }
            } else {
            PaymentMethod = (
                <p className='poppins text-color6 fw-semibold mt-3'>
                Payment not found
                </p>
            );
            }

            // Helper function to render stars
            // const renderStars = (rating) => {
            //     const starCount = Math.round(rating);
            //     const filledStars = '★'.repeat(starCount);
            //     const emptyStars = '☆'.repeat(5 - starCount);
            //     return filledStars + emptyStars;
            // };

            const fulfillment = this.state?.order?.fulfillments[0];
            const recipient = {
                name: fulfillment?.deliveryDetails?.recipient?.displayName || fulfillment?.pickupDetails?.recipient?.displayName,
                email: fulfillment?.deliveryDetails?.recipient?.emailAddress || fulfillment?.pickupDetails?.recipient?.emailAddress,
                phone: fulfillment?.deliveryDetails?.recipient?.phoneNumber || fulfillment?.pickupDetails?.recipient?.phoneNumber
            };

            return (
                <div className='shop bg-color2'>
                  <div className='corner-logo position-absolute d-none d-md-block'>
                    <a href="/">
                      <img className='img-fluid' src={data.corner_logo} alt="" />
                    </a>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className='text-center' style={{ paddingTop: "100px" }}>
                        {/* <Link to="/menu" onClick={(e) => this.props.setLocation(this.props.location)} className='poppins text-color6 fst-italic'>
                          <img src={data.Back} alt={'Go back'} />
                          <small>Back to menu</small>
                        </Link> */}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className='text-center' style={{ paddingTop: "100px" }}>
                        <Link to="/" className='poppins text-color6 fst-italic text-end' onClick={(e) => {
                              localStorage.clear();
                          }} >
                              <img src={data.Back} alt={'Back to home'} />
                              <small>Start new order</small>
                          </Link>
                      </div>
                    </div>
                  </div>
                  <div className="container" style={{ paddingTop: "100px" }}>
                    <div className="row">
                      <div className="col-12 col-md-6 offset-md-3">
                        <h2 className='text-center poppins fw-bold text-color6 mt-5 mb-4'>Your order {this.state.order.fulfillments[0]?.pickupDetails?.recipient?.displayName}</h2>
                        {this.state?.delivery ? <StepProgressBarDelivery fulfillments={this.state.order.fulfillments[0]} delivery={this.state.delivery} /> : <StepProgressBarPickup fulfillments={this.state.order.fulfillments[0]} />}
                      </div>
                    </div>
                    <div className="row mt-3 pb-5">
                      <div className="col-md-5 pt-5">
                        <h4 className='poppins text-color6 fw-bold'>Cart Description</h4>
                        <CartDescription tip={this.state?.order?.tenders[0]?.tipMoney?.amount ?? this.state?.order?.totalTipMoney?.amount} edit={false} items={this.state?.order} catalog={this.props?.catalog} />
                      </div>
                      <div className="col-md-2 offset-md-1 pt-5">
                        <h4 className='poppins text-color6 fw-bold'>Customer</h4>
                        <p className='poppins text-color6 fw-semibold mt-3'>
                          {recipient?.name}<br />
                          {recipient?.email}<br />
                          {recipient?.phone}
                        </p>
                      </div>
                      <div className="col-md-3 offset-md-1 pt-5">
                        <h4 className='poppins text-color6 fw-bold'>Payment Method</h4>
                        {PaymentMethod}
                      </div>
                    </div>
                    {this.state?.order?.fulfillments[0]?.pickupDetails && (
                      <div>
                        <p className='poppins text-color6 fw-semibold'>Pickup at {location?.address?.locality}</p>
                        <a className='poppins text-color6 fw-semilight' rel={"noreferrer"} target={'_blank'} href={'https://maps.google.com/?q=' + (location?.address?.address_line_1 + ',' + location?.address?.locality + ',' + location?.address?.administrative_district_level_1 + ',' + location?.address?.postal_code)}>
                          {location?.address?.address_line_1}, {location?.address?.locality}, {location?.address?.administrative_district_level_1}, {location?.address?.postal_code}
                        </a>
                        <br />
                        <br />
                      </div>

                    )}
                    {this.state?.delivery && (
                      <div className='row mt-3 pb-5'>
                        <br />
                        <hr />
                        <h4 className='poppins text-color6 fw-bold'>Delivery Information</h4>
                        {this.state.delivery?.dasher_name && (
                          <div className="col-md-3 pt-5">
                            <h4 className="poppins text-color6 fw-bold">Dasher Information</h4>
                            {/* <div className="profilePic">
                              <img className="img-fluid rounded-circle" src={this.state.delivery?.courier?.img_href} alt="" />
                            </div> */}
                            <p className="poppins text-color6 fw-bold" style={{ display: 'inline-block', marginRight: '10px' }}>Name:</p>
                            <p className="poppins text-color6" style={{ display: 'inline-block' }}>{this.state.delivery?.dasher_name}</p>
                            
                            {statusText !== 'Delivered' && (
                            <div>
                              <p className="poppins text-color6 fw-bold" style={{ display: 'inline-block', marginRight: '10px' }}>Contact your Dasher:</p>
                              <p className="poppins text-color6" style={{ display: 'inline-block' }}>
                                <a href={`tel:${this.state.delivery?.dasher_dropoff_phone_number}`}>
                                  {this.state.delivery?.dasher_dropoff_phone_number}
                                </a>
                              </p>
                            </div>
                            )}
                            {statusText === 'Delivered' && (
                            <div>
                              <p className="poppins text-color6 fw-bold">Proof Of Delivery:</p>
                              <img className='profilePic' src={this.state.delivery?.dropoff_verification_image_url} alt='Proof Of Delivery' />  
                            </div>)
                            }
                          </div>
                        )}
                        <div className="col-md-3 offset-md-1 pt-5">
                          <h4 className='poppins text-color6 fw-bold'>Delivery Status</h4>
                          <p className="poppins text-color6 fw-bold" style={{ display: 'inline-block', marginRight: '10px' }}>Status:</p>
                          <p className="poppins text-color6" style={{ display: 'inline-block' }}>{statusText}</p>
                          <br />
                          <p className="poppins text-color6 fw-bold" style={{ display: 'inline-block', marginRight: '10px' }}>Drop Off ETA:</p>
                          <p className="poppins text-color6" style={{ display: 'inline-block' }}>
                            {this.state?.delivery?.dropoff_time_estimated && new Date(this.state?.delivery?.dropoff_time_estimated).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' })}
                          </p>
                        </div>
                        {this.state.delivery.status !== 'delivered' && (
                          <div className="col-md-3 offset-md-1 pt-5">
                            <h4 className='poppins text-color6 fw-bold'>Tracking</h4>
                            <button className="tracking-button" onClick={() => window.open(this.state?.delivery?.tracking_url, '_blank')}>
                              Open Tracking
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
              
        }
    }

    async getOrder() {
        const url = process.env.REACT_APP_API_URL + 'orders/';
        const deliveryOrderId = await axios.get(url + '?orderId=' + this.props?.orderId)
          .then(res => {
            let order = { ...this.state?.order };
            order = res?.data;
            if (!res?.data?.lineItems) {
              this.setState({ order });
              return null;
            }
            this.setState({ order, cartLength: Number(res?.data?.lineItems?.reduce((total, item) => total + Number(item.quantity), 0)) });
            if (order?.fulfillments[0]?.deliveryDetails) {
              return order?.fulfillments[0]?.deliveryDetails?.squareDeliveryId;
            }
            return null;
          });
        if (deliveryOrderId) {
          const deliveryRes = await axios.post(`${process.env.REACT_APP_API_URL}delivery/get-doordash-delivery`, {
            'deliveryId': deliveryOrderId
          });
          this.setState({
            delivery: deliveryRes.data.data
          });
        }
      }      
}

export default Order;
