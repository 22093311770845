import React from 'react';
import axios from 'axios';
import data from '../assets/resources';
import './assets/Pay.css';
import CartDescription from '../Shared/cart-description/CartDescription';
import PaymentForm from './components/payment-form/PaymentForm';
import PickupDetails from './components/pickup-details/PickupDetails';
import { Link } from 'react-router-dom';
import TipsPicker from './components/tips-picker/TipsPicker';
import ReactGA from "react-ga4";
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import loadingImage from './../assets/minys/images/loading.gif'


class Pay extends React.Component {

    state = {
        cart: { lineItems: null },
        tip: 0
    };

    componentDidMount() {
        document.title = `Pay | ${process.env.REACT_APP_NAME}`;
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });
        const currentLocation = localStorage.getItem('locationId');
        const orderId = localStorage.getItem('orderId');
        if (currentLocation && orderId) {
            this.props.setLocation(currentLocation);
            this.getCart(orderId);
        } else {
            window.location.href = "/menu";
        }
    }

    render() {
        if (!this.props?.catalog?.length) {
            return (
                <LoadingScreen imageSource={loadingImage} />
            )  
        }
        return (
            <div className='shop bg-color2'>
                <div className='corner-logo position-absolute d-none d-md-block'>
                    <a href="/">
                        <img className='img-fluid' src={data.corner_logo} alt="" />
                    </a>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className='text-center' style={{paddingTop: "100px"}}>
                            <a href="/menu" className='poppins text-color6 fst-italic'>
                                <img src={data.Back} alt={'back to home'}/>
                                <small>Back to menu</small>
                            </a>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className='text-center' style={{paddingTop: "100px"}}>
                            <Link to="/" className='poppins text-color6 fst-italic text-end' onClick={(e) => {
                                localStorage.clear();
                            }} >
                                <img src={data.Back} alt={'Back to home'} />
                                <small>Start new order</small>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="pt-5">
                            <TipsPicker cart={this.state?.cart} submitTip={this.submitTip}/>
                                <div>
                                    <h3 className='poppins text-color6'>Contact</h3>
                                    <PaymentForm cart={this.state?.cart} location={this.props?.location} addPickupDetails={this.addPickupDetails} tip={this.state.tip} attributes={this.props.attributes}/>
                                </div> 
                            </div>
                        </div>
                        <div className="col-12 col-md-6 pt-5"><h3 className='poppins text-color6 mt-5'>Cart</h3>
                            <div className="items-description mb-5">
                                <CartDescription edit={false} updateItem={this.updateItem} items={this.state?.cart} setQuantity={this.setQuantity} catalog={this.props?.catalog} tip={this.state?.tip}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    addPickupDetails = (state) => {
        const currentLocation = localStorage.getItem('locationId');
        const orderId = localStorage.getItem('orderId');
        const body = {
            locationId: currentLocation,
            orderId: orderId,
            pickupName: state?.pickup_details?.name,
            pickupEmail: state?.pickup_details?.email,
            pickupNumber: state?.pickup_details?.phone
        }
        axios.patch(process.env.REACT_APP_API_URL + 'orders/update', body).then(res => {
            this.setState({ cart: res?.data?.order });
        });
    }

    getCart(orderId) {
        axios.get(process.env.REACT_APP_API_URL + 'orders/?orderId=' + orderId).then(res => {
            if (!res?.data?.lineItems || res?.data?.lineItems.length === 0) {
                window.location.href = "/menu";
            }
            this.setState({ cart: res?.data })
        });

    }

    pickupOrPayment() {
        if (this.state?.cart?.fulfillments)
            return (
                <div>
                    <h3 className='poppins text-color6'>Contact</h3>
                    <PaymentForm cart={this.state?.cart} location={this.props?.location} addPickupDetails={this.addPickupDetails} tip={this.state.tip} attributes={this.props.attributes}/>
                </div>
            );
        else
            return (
                <div>
                    <h3 className='poppins text-color6'>Contact</h3>
                    <PickupDetails addPickupDetails={this.addPickupDetails} />
                </div>
            );
    }

    setQuantity = (uid, quantity) => {
        this.state?.cart?.lineItems?.forEach(item => {
            if (item.uid === uid) {
                item.quantity = quantity;
                if (quantity !== 0)
                    axios.patch(
                        process.env.REACT_APP_API_URL + 'orders/update', {
                        "orderId": this.state?.cart?.id,
                        "locationId": this.state?.cart?.locationId,
                        "lineItems": this.state?.cart?.lineItems,
                        "calculationPhase": "TOTAL_PHASE",
                        "state": "OPEN"
                    })
                        .then(res => {
                            localStorage.setItem('orderId', res?.data?.order?.id);
                            this.setState({ cart: res?.data?.order });
                        });
                else {
                    axios.patch(
                        process.env.REACT_APP_API_URL + 'orders/update', {
                        "orderId": this.state?.cart?.id,
                        "deleteItem": [uid],
                        "locationId": this.state?.cart?.locationId,
                        "calculationPhase": "TOTAL_PHASE",
                        "state": "OPEN"
                    })
                        .then(res => {
                            localStorage.setItem('orderId', res?.data?.order?.id);
                            this.setState({ cart: res?.data?.order });
                        });
                }
            }
        });
    }

    updateItem = (product_to_update) => {
        let cart = this.state?.cart;
        const updatedLineItems = cart?.lineItems?.map((item) => {
            if (item.uid === product_to_update?.uid) {
                return {
                    ...item,
                    modifiers: product_to_update.modifiers,
                    quantity: product_to_update.quantity,
                    catalogObjectId: product_to_update.variation,
                };
            }
            return item;
        });

        const updatedLineItemsWithDeletedFields = updatedLineItems.reduce((acc, item) => {
            return {
                ...acc,
                [item.uid]: {
                    ...item,
                    grossSalesMoney: undefined,
                    totalTaxMoney: undefined,
                    totalMoney: undefined,
                    totalDiscountMoney: undefined,
                    appliedTaxes: undefined
                }
            }
        }, {});

        axios.patch(
            process.env.REACT_APP_API_URL + 'orders/update', {
            "orderId": cart?.id,
            "locationId": this.props.location,
            "deleteItem": product_to_update.modifiers_to_delete,
            "calculationPhase": "TOTAL_PHASE",
            "state": "OPEN"
        })
            .then(res =>
                axios.patch(
                    process.env.REACT_APP_API_URL + 'orders/update', {
                    "orderId": cart?.id,
                    "locationId": this.props.location,
                    "lineItems": updatedLineItemsWithDeletedFields,
                    "calculationPhase": "TOTAL_PHASE",
                    "state": "OPEN"
                })
                    .then(res => this.setState({ cart: res?.data.order }))
            );
    }

    submitTip = (tip) => {
        this.setState({tip});
    }

}

export default Pay;
