import React from 'react';
import axios from 'axios';
import validator from 'validator';
import './assets/Login.css';
import ReactGA from 'react-ga4';
import data from '../assets/resources';

class Login extends React.Component {
    componentDidMount() {
        document.title = `Login | ${process.env.REACT_APP_NAME}`;
        document.querySelector('meta[name="description"]').setAttribute("content", process.env.REACT_APP_DESCRIPTION);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });
    }

    state = {
        email: '',
        password: '',
        errorMessage: '',
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            errorMessage: '',
        });
    };

    validateForm = () => {
        const { email, password } = this.state;
        const errors = [];

        if (!validator.isEmail(email)) {
            errors.push('Invalid email address');
        }

        if (!password) {
            errors.push('Passwords cannot be empty');
        }

        if (errors.length > 0) {
            this.setState({ errorMessage: errors.join(', ') });
            return false;
        }

        return true;
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        if (!this.validateForm()) {
            return;
        }

        const { email, password } = this.state;

        try {
            // Send registration data to the backend
            const response = await axios.post(process.env.REACT_APP_API_URL + 'customer/login', {
                email,
                password
            });
            // Handle the response from the backend as needed
            console.log('Registration successful:', response.data);

        } catch (error) {
            // Handle registration errors
            console.error('Registration failed:', error.response.data);
            this.setState({ errorMessage: error.response.data.message || 'Registration failed' });
        }
    };

    render() {
        const {
            email,
            password,
            errorMessage,
        } = this.state;

        return (
            <div className='login bg-color2'>
                <div className='corner-logo position-absolute d-none d-md-block'>
                    <a href="/">
                        <img className='img-fluid' src={data.corner_logo} alt="" />
                    </a>
                </div>
                <div className='text-center p-5'>
                    <div className='row'>
                        <div className='col-10 offset-1 col-md-4 offset-md-4 bg-color3 rounded'>
                            <img className='img-fluid my-5' src={data.logo_login} alt="Miny's Mexican Restaurant"/>
                            <form onSubmit={this.handleSubmit} className='p-5'>
                                <div className='form-group m-2'>
                                    <label className='text-color1 fw-bold'>Email:</label><br />
                                    <input
                                        type="email"
                                        className="w-100 my-2 bg-color2"
                                        name="email"
                                        value={email}
                                        onChange={this.handleChange}
                                        required
                                    />
                                    {!validator.isEmail(email) && (
                                        <div className="error">Invalid email address</div>
                                    )}
                                </div>
                                <br />

                                <div className='form-group m-2'>
                                    <label className='text-color1 fw-bold'>Password:</label><br />
                                    <input
                                        type="password"
                                        className="w-100 my-2 bg-color2"
                                        name="password"
                                        value={password}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </div>
                                <br />

                                <button type="submit" className='btn bg-color4 poppins w-20 fw-semibold text-color6'>Login</button>
                            </form>
                        </div>

                    </div>
                </div>
                {errorMessage && <p>{errorMessage}</p>}
            </div>
        );
    }
}

export default Login;