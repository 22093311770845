import React from 'react';
import { NumericFormat } from 'react-number-format';
import './assets/TipcsPicker.css';

class TipsPicker extends React.Component {
  state = {
    tip: 0,
    selectedButton: null
  };

  handleButtonClick = (tip,selected) => {
    this.setState({ tip, selectedButton: selected });
    this.props?.submitTip(Math.round(tip));
  };
  componentDidMount() {
    this.handleButtonClick(this.props.cart?.totalMoney?.amount * 0.15, '15_percent');
  }


  render() {
    const { cart } = this.props;
    const { selectedButton } = this.state;

    return (
      <div>
        <h3 className="poppins text-color6">Add a gratuity</h3>
        <div className="row">
          <div className="col-3">
            <button
              className={`btn bg-color3 shadow-sm poppins text-color6 fw-semibold w-100 ${selectedButton === '10_percent' ? 'selected' : ''}`}

              onClick={() => this.handleButtonClick(cart?.totalMoney?.amount * 0.10,'10_percent')}
            >
              <span className="h4 fw-bold">10%</span>
              <br />
              <small>
                (<NumericFormat
                  value={cart?.totalMoney?.amount * 0.10 / 100}
                  displayType="text"
                  thousandSeparator={true}
                  prefix={'$'}
                  fixedDecimalScale={true}
                  decimalScale={2}
                />)
              </small>
            </button>
          </div>
          <div className="col-3">
            <button
              className={`btn bg-color3 shadow-sm poppins text-color6 fw-semibold w-100 ${selectedButton === '15_percent' ? 'selected' : ''}`}
              onClick={() => this.handleButtonClick(cart?.totalMoney?.amount * 0.15,'15_percent')}
            >
              <span className="h4 fw-bold">15%</span>
              <br />
              <small>
                (<NumericFormat
                  value={cart?.totalMoney?.amount * 0.15 / 100}
                  displayType="text"
                  thousandSeparator={true}
                  prefix={'$'}
                  fixedDecimalScale={true}
                  decimalScale={2}
                />)
              </small>
            </button>
          </div>
          <div className="col-3">
            <button
              className={`btn bg-color3 shadow-sm poppins text-color6 fw-semibold w-100 ${selectedButton === '20_percent' ? 'selected' : ''}`}
              onClick={() => this.handleButtonClick(cart?.totalMoney?.amount * 0.20,'20_percent')}
            >
              <span className="h4 fw-bold">20%</span>
              <br />
              <small>
                (<NumericFormat
                  value={cart?.totalMoney?.amount * 0.20 / 100}
                  displayType="text"
                  thousandSeparator={true}
                  prefix={'$'}
                  fixedDecimalScale={true}
                  decimalScale={2}
                />)
              </small>
            </button>
          </div>
          <div className="col-3">
            <button
              className={`btn bg-color3 shadow-sm poppins text-color6 fw-semibold w-100 ${selectedButton === 'custom' ? 'selected' : ''}`}
              data-bs-toggle="modal"
              data-bs-target="#customTipModal"
            >
              <input type="hidden" ref={(input) => (this.tipHiddenInput = input)} />
              <span className="h5 fw-bold">Custom Tip</span>
            </button>
            <div className="modal fade" id="customTipModal" tabIndex="-1" role="dialog" aria-labelledby="customTipModalLabel" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content bg-color3">
                  <div className="modal-header">
                    <h5 className="modal-title poppins text-color6" id="customTipModalLabel">
                      Add a custom tip
                    </h5>
                  </div>
                  <div className="modal-body bg-color2">
                    <input
                      type="number"
                      className="w-100 h1"
                      placeholder="0.00"
                      ref={(input) => (this.tipInput = input)}
                      onClick={() => this.tipInput.select()}
                    />
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-danger poppins" data-bs-dismiss="modal">
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn bg-color6 text-white poppins"
                      onClick={() => this.handleButtonClick(this.tipInput.value * 100,'custom')}
                      data-bs-dismiss="modal"
                    >
                      Save tip
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TipsPicker;
