import React from 'react';
import axios from 'axios';
import data from '../assets/resources';
import './assets/Shop.css';
import CategoriesList from './components/categories-list/CategoriesList';
import Locations from './components/locations/Locations';
import Products from './components/products/Products';
import Cart from './components/cart/Cart';
import CategoriesListHorizontal from './components/categories-list-horizontal/CategoriesListHorizontal';
import ReactGA from "react-ga4";
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import loadingImage from './../assets/minys/images/loading.gif'
import { Link } from 'react-router-dom';


let loaded = false;
class Shop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cart: null,
            cartLength: 0,
            promotions: this.props.multimedia,
            createOrder:true,
        };
    }
    
    componentDidMount() {
        const currentLocation = localStorage.getItem('locationId');
        const orderId = localStorage.getItem('orderId');
        if(currentLocation && orderId){
            this.setLocation(currentLocation);
            this.getCart(orderId);
        } else if(currentLocation && !orderId){
            this.createOrderId(currentLocation);
        }
        document.title = `Shop | ${process.env.REACT_APP_NAME}`;
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });
    }
    
    componentDidUpdate(prevProps, prevState) {
        const { location } = this.props;
        const prevLocation = prevProps?.location
        const currentLocation = localStorage.getItem('locationId');
        const orderId = localStorage.getItem('orderId');
        if(prevLocation && location && prevProps?.location !== location){
            this.setLocation(location);
            localStorage.setItem('locationId', location);
            this.createOrderId(location);
            this.setState({promotions: this.props.multimedia})
         } else if (location === null) {
                if (location && orderId) {
                    this.setLocation(currentLocation);
                    this.getCart(orderId);
                } 
            } else if (location === currentLocation && !loaded) {
                this.getCart(orderId);
                this.setState({promotions: this.props.multimedia})
                loaded = true;
            } else if (!currentLocation && !orderId) {
                localStorage.setItem('locationId', location);
                this.createOrderId(location);
            } else if(!prevLocation && !location){
                window.location.href = "/";
            }        
    }
    

    createOrderId = (location) => {
        const url = process.env.REACT_APP_API_URL + 'orders/';
        axios.post(url + 'create', {
            locationId: location,
            lineItems: [],
            calculationPhase: 'TOTAL_PHASE',
            state: 'OPEN'
        })
            .then(res => {
                if(res.data.name === 'Error'){
                    alert(res.data.message);
                } else {
                    localStorage.setItem('orderId', res?.data?.order?.id);
                    localStorage.setItem('orderType', (new URL(window.location.href))?.pathname === "/menu" ? "regular" : "catering");
                    this.setState({ cart: res?.data, cartLength: 0 });
                }
            }
            );
    }

    getCart = (orderId) => {
        if(orderId){
            const url = process.env.REACT_APP_API_URL + 'orders/';
            axios.get(url + '?orderId=' + orderId).then(res => {
                if (!res?.data?.lineItems) {
                    let cart = { ...this.state?.cart }
                    cart.order = res?.data;
                    this.setState({ cart });
                } else if (res?.data?.tenders) {
                    const tenders = res?.data?.tenders;
                    if (tenders.length > 0) {
                        let shouldRedirect = false;
                        tenders.forEach((tender) => {
                            if (tender.type === 'CARD' && tender.cardDetails?.status === 'CAPTURED') {
                                shouldRedirect = true;
                            } else if (tender.type === 'WALLET') {
                                shouldRedirect = true;
                            }
                        });
                        if (shouldRedirect) {
                            localStorage.clear();
                            window.location.href = `/order/${orderId}`;
                        } else {
                            let cart = { ...this.state?.cart }
                            cart.order = res?.data;
                            this.setState({ cart: cart, cartLength: Number(res?.data?.lineItems?.reduce((total, item) => total + Number(item.quantity), 0)) });
                        }
                    }
                } else if(res?.data?.fulfillments?.length > 0){
                    window.location.href = `/pay`;
                } 
                else {
                    let cart = { ...this.state?.cart }
                    cart.order = res?.data;
                    this.setState({ cart: cart, cartLength: Number(res?.data?.lineItems?.reduce((total, item) => total + Number(item.quantity), 0)) });
                }
            });
        }
    }

    setLocation = (location) => {
        localStorage.setItem('locationId', location);
        this.props.setLocation(location);
    };

    addItemToCart = (state) => {
        if (
            ((new URL(window.location.href))?.pathname === "/menu" && localStorage.getItem("orderType") === "regular") ||
            ((new URL(window.location.href))?.pathname === "/catering" && localStorage.getItem("orderType") === "catering")
        ) {
            let cart;
            if (this.state?.cart?.order) {
                cart = this.state?.cart;
                if (!cart?.order?.lineItems) {
                    cart.order.lineItems = [];
                }
                cart?.order?.lineItems.push(state?.product_to_add);
                this.setState({ cart })
            }
            else {
                cart = { order: [state?.product_to_add], ...this.state?.cart };
            }
            axios.patch(
                process.env.REACT_APP_API_URL + 'orders/update', {
                "orderId": cart?.order?.id,
                "locationId": this.props.location,
                "lineItems": cart?.order?.lineItems,
                "calculationPhase": "TOTAL_PHASE",
                "state": "OPEN"
            }).then(res => {
                this.setState({
                    cart: res?.data,
                    cartLength: Number(res?.data?.order?.lineItems?.reduce((total, item) => total + Number(item.quantity), 0))
                });
            });
        }
        else {
            var startNewCart = window.confirm("You already have a " + (localStorage.getItem("orderType")) + " cart, do you like to start a new " + (localStorage.getItem("orderType") === "regular" ? "catering" : "regular") + " cart? ")
            if (startNewCart) {
                //this.createOrderId()
                const url = process.env.REACT_APP_API_URL + 'orders/';
                axios.post(url + 'create', {
                    "locationId": this.props.location,
                    "lineItems": [],
                    "calculationPhase": "TOTAL_PHASE",
                    "state": "OPEN"
                }).then(res => {
                    localStorage.setItem('orderId', res?.data?.order?.id);
                    localStorage.setItem('orderType', (new URL(window.location.href))?.pathname === "/menu" ? "regular" : "catering");
                    axios.patch(
                        process.env.REACT_APP_API_URL + 'orders/update', {
                        "orderId": res.data?.order?.id,
                        "locationId": this.props.location,
                        "lineItems": [state?.product_to_add],
                        "calculationPhase": "TOTAL_PHASE",
                        "state": "OPEN"
                    }).then(res => {
                        this.setState({
                            cart: res?.data,
                            cartLength: Number(res?.data?.order?.lineItems?.reduce((total, item) => total + Number(item.quantity), 0))
                        });
                    });
                });
            }
        }
    }

    setQuantity = (uid, quantity) => {
        this.state?.cart?.order?.lineItems?.forEach(item => {
            if (item.uid === uid) {
                item.quantity = quantity;
                if (quantity !== 0)
                    axios.patch(
                        process.env.REACT_APP_API_URL + 'orders/update', {
                        "orderId": this.state?.cart?.order?.id,
                        "locationId": this.props.location,
                        "lineItems": this.state?.cart?.order?.lineItems,
                        "calculationPhase": "TOTAL_PHASE",
                        "state": "OPEN"
                    }).then(res => {
                        localStorage.setItem('orderId', res?.data?.order?.id);
                        this.setState({ cart: res?.data, cartLength: Number(res?.data?.order?.lineItems?.reduce((total, item) => total + Number(item.quantity), 0)) });
                    });
                else {
                    axios.patch(
                        process.env.REACT_APP_API_URL + 'orders/update', {
                        "orderId": this.state?.cart?.order?.id,
                        "deleteItem": [uid],
                        "locationId": this.props.location,
                        "calculationPhase": "TOTAL_PHASE",
                        "state": "OPEN"
                    }).then(res => {
                        localStorage.setItem('orderId', res?.data?.order?.id);
                        if (res?.data?.order?.lineItems)
                            this.setState({ cart: res?.data, cartLength: Number(res?.data?.order?.lineItems?.reduce((total, item) => total + Number(item.quantity), 0)) });
                        else
                            this.setState({ cart: res?.data, cartLength: 0 });
                    });
                }
            }
        });
    }

    render() {
        if (!this.props?.catalog?.length) {
            return (
                <LoadingScreen imageSource={loadingImage} />
            )
        }

        if (!this.state.cart) {
            this.getCart(localStorage.getItem("orderId"));
        }

        let promos = [];
        this.state?.promotions?.forEach((promo, index) => {
            promos.push(
                <div className={index === 0 ? "carousel-item active" : "carousel-item"} key={"promo_" + index}>
                    <img src={promo} className="d-block w-100" alt="Promo" />
                </div>
            )
        })

        return (

            <div className='shop bg-color2'>
                <div className='corner-logo position-absolute d-none d-md-block'>
                    <a href="/">
                        <img className='img-fluid' src={data.corner_logo} alt="" />
                    </a>
                </div>
                <div className="sticky-top d-md-none">
                    <div className="row">
                        <div className="col-12 col-md-6 offset-md-6 text-end">
                            <div className='header'>
                                <div className="row">
                                    <div className="col-md-4 offset-md-6 col-6 p-3">
                                        <div className="dropdown bg-color2 rounded-pill">
                                            <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src={data.LocationIcon} alt="" />
                                                <strong className='text-color6 ms-3'>{this.props.locations.find(location => location.id === this.props.location).address.locality}</strong>
                                            </button>
                                            <Locations locations={this.props.locations} setLocation={this.setLocation} />
                                        </div>
                                    </div>
                                    <div className="col-md-2 col-3 p-3">
                                        <button className="btn bg-color2 rounded-pill" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCart" aria-expanded="false" aria-controls="collapseCart" disabled={this.state?.cartLength <= 0}>
                                            <img src={data.CartIcon} alt="" />
                                            <span className="badge rounded-circle bg-color4 text-color6 position-absolute mt-3">
                                                {this.state?.cartLength}
                                            </span>
                                        </button>
                                        <Cart cart={this.state?.cart} setQuantity={this.setQuantity} proced={this.state?.cartLength <= 0} />
                                    </div>
                                    <div className="col-3 d-md-none p-3">
                                        <div className="dropdown bg-color2 rounded-pill">
                                            <button className="btn dropdown-toggle fw-semibold text-color6 poppins" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Categories
                                            </button>
                                            <CategoriesListHorizontal catalog={this.props.catalog} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='header sticky-top d-none d-md-block'>
                    <div className="row">
                        <div className="col-md-2 offset-md-7 col-6 p-3">
                            {/* <Link className="text-color6 text-decoration-none" to={(new URL(window.location.href))?.pathname === "/menu" ? "/catering" : "/menu"}>
                                <span className='poppins fw-bold'>
                                {
                                    (new URL(window.location.href))?.pathname === "/menu" ? 
                                    "Order Catering" : "Main Menu"
                                }
                                </span>
                            </Link> */}
                        </div>
                        <div className="col-md-2 col-6 p-3">
                            <div className="dropdown bg-color2 rounded-pill">
                                <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src={data.LocationIcon} alt="" />
                                    <strong className='text-color6 ms-3'>{this.props.locations.find(location => location.id === this.props.location).address.locality}</strong>
                                </button>
                                <Locations locations={this.props.locations} setLocation={this.setLocation} />
                            </div>
                        </div>
                        <div className="col-md-1 col-3 p-3">
                            <button className="btn bg-color2 rounded-pill" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCart" aria-expanded="false" aria-controls="collapseCart" disabled={(this.state?.cartLength <= 0 || !this.props?.open)}>
                                <img src={data.CartIcon} alt="" />
                                <span className="badge rounded-circle bg-color4 text-color6 position-absolute mt-3">
                                    {this.state?.cartLength}
                                </span>
                            </button>
                            <Cart cart={this.state?.cart} setQuantity={this.setQuantity} proced={this.state?.cartLength <= 0} />
                        </div>
                        <div className="col-3 d-md-none p-3">
                            <div className="dropdown bg-color2 rounded-pill">
                                <button className="btn dropdown-toggle fw-semibold text-color6 poppins" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Categories
                                </button>
                                <CategoriesListHorizontal catalog={this.props.catalog} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-color2 pt-5">
                    {/*<div className='shop-image d-none d-md-block'></div>*/}
                    <div className="carousel-wrapper">
                        <div className="carousel" data-ride="carousel" data-interval="5000">
                            <div className="carousel-inner">
                                {promos}
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target=".carousel" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target=".carousel" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>


                    <div className="row">
                        <Link to="/" className='poppins text-color6 fst-italic text-end' onClick={(e) => {
                            localStorage.clear();
                        }} >
                            <img src={data.Back} alt={'Back to home'} />
                            <small>Start new order</small>
                        </Link>
                        {
                            (new URL(window.location.href))?.pathname === "/menu" ?
                            <React.Fragment>
                                <div className="col-md-2 d-none d-md-block">
                                    <CategoriesList catalog={this.props.catalog} />
                                </div>
                                <div className="col-md-10 col-12 p-5">
                                    <Products open={this.props.open} catalog={this.props.catalog} cart={this.state.cart} addItemToCart={this.addItemToCart} />
                                </div>
                            </React.Fragment> :
                            <React.Fragment>
                                <div className="col-md-12 col-12 p-5">
                                    <Products open={this.props.open} catalog={this.props.catalog} cart={this.state.cart} addItemToCart={this.addItemToCart} />
                                </div>
                            </React.Fragment>
                        }
                        
                    </div>
                </div>
            </div>
        )
    }

   
}

export default Shop;
